import React, { Suspense, lazy, useState, useCallback, useEffect } from "react"
import Helmet from "react-helmet"
import { Router } from "@reach/router"
import InViewObserver from "../components/InViewObserver"

import Link from "../components/LinkWithTransitionLock";
import Page_Footer from './page_footer'
// import Scrollbar from 'smooth-scrollbar';

import { TimelineMax, TweenMax, TweenLite, Linear, TextPlugin, ThrowPropsPlugin, EasePack, CSSPlugin, ScrollToPlugin, Power1, Power2, Power4, Expo, gsap } from 'gsap';

class Page extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount () {
    
    InViewObserver()
    
  }
 
  componentWillUnmount() {

  }


  render() {

    return (
      // <>

      <div className="content_wrap">

        <div className="page_header">
          <h2 className="sub_title">台北萬華　最美的是人</h2>
          <h1 className="main_title">柳隅茶社<a href="https://www.facebook.com/liuyutea" className="location" target="_blank">↗</a></h1>
          <h6 className="speaker">店主/藍字鈞　筆者/林立青</h6>
        </div>

        <div className="page_content">

          <section className="row_area_s project_a_muse_2_a">
            <div className="column_5">
              <div className="context">
                <p>位於龍山地下街的柳隅茶舍，有著捷運共構的便利性，獨特的空間體驗，還用特別的方式回饋地方行善，很難想像在台灣茶藝館越來越少的這個時代，店主人用文化導覽和在地的故事傳承，反倒建立了不可替代的觀光旅遊體驗。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_2_b">
            <div className="column_8">
              <div className="video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/GDD12O6R0f4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_2_c">
            <div className="column_6">
              <div className="context">
                <p>走進柳隅茶舍，很難想像龍山寺地下街有這樣一個空間：暖色系的照明均勻，木桌上各式茶具細緻而典雅，古樸的黑色方桌配上宣紙印刷的菜單，更顯得品味非凡，茶點用瓷碗木盤端上，店主人母女提供教學和引導，總會當你泡上第一泡茶，確認來客已經能掌握茶性以後，才笑著隱於櫃檯。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_2_d">
            <div className="column_6">
              <div className="context">
                <p>這麼特別的空間經營，是來自於店主人藍子鈞，她過去在加拿大留學生活，回國後，說服當時經營卡拉OK的母親，進行小型革命，將家裡店面改為茶藝文化體驗空間，帶動了全新的消費和觀光體驗，這樣的轉型來自於她對台灣當地茶文化特色的理解。由於接近於台北市龍山寺下方，有廣大觀光客群，新舊交替之下，越在地則越國際，為符合歐美消費者的茶飲習慣，店內的茶具經過設計都是一人一套，店主還能同時用英、法、西、台、華、粵、日語等六國語言和客人對談，一人一茶，很容易交換並且開啟話題。</p>
                
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_2_e">
            <div className="column_5">
              <div className="context">
                <h6>她在外國觀光客口中稱之為「Gin」，也在外國客群中成為台灣文化的引路人。</h6>
                <p>在這艋舺人首選的交誼空間，聽著人們談論家鄉過去的興盛繁華，今日的衰敗老化，藍子鈞認為可以做一些什麼，因此開始串聯店家，擴大推廣柳隅茶舍的茶飲體驗，認識了更多經營社區的人們，她覺得艋舺應該要有可以體驗台灣文化的空間，所以開辦導覽、提供講座、主持茶會並且經營多語觀光驗活動；轉型後的柳隅茶舍是當地鄉紳老師及官員造訪之地，及接待訪客及談話的主要空間，這樣的交誼中心承載了各種情報及政令宣導，也提供訊息校正和資源分享。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_2_f">
            <div className="column_4">
              <div className="context">
                <p>子鈞找出當地最具特色的餐點作為交流體驗，有時更會自己製作特色飲品，同時接受訂做外送，不管是NGO的組織聚餐，還是地方公益市集，都能看到柳隅茶舍的鼎力支持，在社福NGO中，「Gin」不只是時常笑盈盈地將餐點物資交託在社工手中的善人，她也是艋舺在地協會進行弱勢家庭培力計畫中，最好的「Gin」老師，她常帶著弱勢家戶學習服務和接待，教簡單應對卻禮貌的方法，使當地人能夠被聘僱和肯定，藉此翻轉生活。</p>
                <h6>柳隅茶舍的轉型故事和善舉開啟了人們認識萬華的另一個角度，而「Gin」面對外國客人時的態度和經營茶舍的理念，儼然成為一種典範。</h6>
              </div>
            </div>
          </section>

        </div>

        <div className="page_footer">
          <Link to="/project_a_muse_4" className="next_page">
            <div className="next_title">八海火鍋</div>
            <div className="next_owner">店主/賴珮立　筆者/林立青</div>
          </Link>
        </div>

      </div>

      // </>
    )

  }

}


export default Page

